<template>
  <v-app>
    <main>
      <v-container fluid fill-height>
        <v-row class="align-center justify-center">
          <v-col cols="12" md="6" xl="4">
            <div class="d-flex justify-center">
              <v-img
                class="global-logoimg mt-6"
                max-width="260"
                src="@/assets/logo-mclick-min.png"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-1"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>

            <h4 class="text-h4 my-6 text-center">Cambiar contraseña</h4>

            <v-card>
              <v-card-title class="headline text-center"
                >Establecer nueva contraseña</v-card-title
              >
              <v-card-text>
                <v-form ref="newpass">
                  <p class="caption">
                    Para continuar ingresa una nueva contraseña e inicia sesión.
                  </p>

                  <v-alert
                    class="alertStyle alert align-center"
                    color="#F2F2F2"
                    icon="bi bi-exclamation-diamond"
                    dense
                  >
                    La contraseña debe ser alfanumérica y podrá contener entre 8
                    a 15 caracteres.
                  </v-alert>

                  <v-text-field
                    :label="NewPassword.label"
                    :placeholder="NewPassword.placeholder"
                    :rules="NewPassword.rules"
                    v-model="NewPassword.text"
                    :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="show1 = !show1"
                  ></v-text-field>

                  <v-text-field
                    v-model="ConfirmPassword.text"
                    :rules="ConfirmPassword.rules"
                    :lable="ConfirmPassword.label"
                    :placeholder="ConfirmPassword.placeholder"
                    :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="show2 = !show2"
                  ></v-text-field>
                </v-form>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" text @click="back" :disabled="loading"
                  ><v-icon left>mdi-arrow-left</v-icon> Regresar</v-btn
                >
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  @click="UpdatePassword()"
                  :loading="loading"
                  >Confirmar <v-icon right>mdi-check</v-icon></v-btn
                >
              </v-card-actions>
            </v-card>

            <v-alert
              v-model="error"
              type="error"
              outlined
              dismissible
              close
              class="mt-3"
            >
              {{ errormsg }}
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </main>
  </v-app>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      show1: false,
      show2: false,
      profile_data: {},
      error: false,
      errormsg: "",
      NewPassword: {
        placeholder: "Escriba su nueva contraseña",
        label: "Nueva contraseña",
        text: "",
        type: "password",
        rules: [
          (value) => !!value || "La contraseña es un campo requerido.",
          (value) =>
            (value.length >= 8 && value.length <= 15) ||
            "La contraseña debe tener entre 8 y 15 caracteres",
        ],
      },

      ConfirmPassword: {
        placeholder: "Escriba nuevamente su contraseña...",
        label: "Confirmar nueva contraseña",
        text: "",
        type: "password",
        rules: [
          (value) =>
            !!value || "Confirmar la contraseña es un campo requerido.",
          (value) =>
            value === this.NewPassword.text || "Las contraseñas no coinciden.",
        ],
      },
    };
  },
  methods: {
    back() {
      this.$router.push({ name: "Login" });
    },
    UpdatePassword() {
      if (this.$refs.newpass.validate()) {
        this.loading = true;
        var password = {
          clave_temporal: this.$store.getters.clave,
          newpassword: this.NewPassword.text,
          token: this.$store.getters.token,
        };
        this.$store
          .dispatch("updateClave", password)
          .then(() => {
            this.$router.push({ name: "Home" });
          })
          .catch((e) => {
            this.errormsg = e;
            this.error = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  computed: {
    loadingstatus() {
      return this.$store.getters.loadingstatus;
    },
  },
  mounted() {
    // console.log("clave temporaal: ", this.$store.getters.clave);
    // console.log("token temporaal: ", this.$store.getters.token);
    this.profile_data = JSON.parse(sessionStorage.getItem("UserData"));
    // console.log("profile data: ", this.profile_data);
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@600&display=swap");

.recover-text {
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: #050c42;
}
.large {
  font-family: "Inter", sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 600;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #0077c8;
}
.small {
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
}
.text-field {
  height: 44px;
  width: 570px;
  border-radius: 5px;
}
.alert {
  font-family: "Inter", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: center;
  color: #050c42 !important;
}

.alertStyle {
  border-radius: 10px;
  align-content: center;
}
::v-deep .v-alert__icon {
  margin-right: 0px;
  color: #050c42;
  margin-top: 3px;
}
</style>
